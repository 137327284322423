<template>
    <div class="login_main">
        <div class="main">
            <div class="main_padding">
                <div class="title">Login</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item prop="userId">
                        <el-input v-model.number="ruleForm.userId"  prefix-icon="el-icon-user" placeholder="Please enter your login ID"></el-input>
                    </el-form-item>
                    <el-form-item  prop="pass">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"  prefix-icon="el-icon-lock" placeholder="Please enter your password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')" class="submit_btn">Login</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { getToken, setToken } from '@/utils/auth'
export default {
    data() {
        var checkAge = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('ID cannot be empty'));
            }
            callback()
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please enter the password'));
            } else {
                if(value.length < 6 || value.length > 16){
                   callback(new Error('Password length 6-16 bits')); 
                }
            }
            callback()
        };
        return {
            ruleForm: {
                pass: '',
                userId: ''
            },
            rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                userId: [
                    { validator: checkAge, trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        let that = this;
        document.onkeydown = function (e) {
            e = window.event || e
            //保证是在登录页面发出的enter事件
            if ((that.$route.path === '/login') && (e.code === 'Enter' || e.code === 'enter')) {
                //调用登录函数
                that.submitForm('ruleForm');
            }
        }
    },
    methods: {
      submitForm(ruleForm) {
        this.$refs[ruleForm].validate(valid => {
          if (valid) {
            if(this.ruleForm.userId == 'admin' && this.ruleForm.pass == 'XinDa1') {
                let token = '29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
                this.$store.commit('SET_TOKEN',token) 
                setToken(token)
                this.$router.push('/')
            }else {
                this.$message.error('ID or password error');
            }
          } else {
            return false;
          }
        });
      }
    }
}
</script>
<style scoped>
    .login_main {
        width: 100%;
        height: 100%;
        background: url('../assets/images/login_bg.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
    }
    .main {
        position: absolute;
        top: 0;
        bottom: 10%;
        left: 0;
        right: 0;
        margin: auto;
        width: 9.3875rem;
        height: 6.4875rem;
        background: url('../assets/images/login_from.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
    }
    .main_padding {
        width: 100%;
        height: 100%;
    }
    .title {
        width: 100%;
        height: .6375rem;
        font-size: .65rem;
        font-weight: 500;
        color: #FEFEFE;
        line-height: .375rem;
        font-family: 'myfont';
        margin-top: .625rem;
        margin-bottom: .625rem;
    }
    @font-face {
        font-family: 'myfont';
        src: url("../assets/font/Roboto-Bold.ttf");
    }
    .demo-ruleForm {
        width: 7.65rem;
        margin: 0 auto;
    }
    /deep/ .el-input--prefix {
        height: 100%;
        height:.825rem;
        border: .0375rem solid rgba(32, 163, 245, 1);
        opacity: 0.4;
        margin:  0 auto;
        line-height: .825rem;
        color: #eee;
    }
    /deep/ .el-input__inner {
        width: 100%;
        height: 100%;
        background: rgba(24, 136, 203, 0.3);
        border:none;
        font-size: .3rem;
        color: #eee;
        padding-left: 1rem;
    }
    /deep/ .el-icon-user{
        font-size: .375rem;
        color: rgb(0 194 252);
        height: .825rem;
        line-height: .825rem;
        margin-left: .425rem;
        font-weight: 600;
    }
    /deep/ .el-icon-lock{
        font-size: .375rem;
        color: rgb(0 194 252);
        height: .825rem;
        line-height: .825rem;
        margin-left: .425rem;
        font-weight: 600;
    }
    /deep/ .el-form-item {
        margin-bottom: .375rem;
    }
    /deep/ .el-form-item__error {
        font-size: .225rem;
    }
    .submit_btn {
        width: 7.65rem;
        height: .825rem;
        background: #20A3F5;
        font-size: .4rem;
    }
</style>